/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper/core'

// Components
import ParseContent from 'components/shared/ParseContent'

// Hooks
import useProject from 'hooks/useProject'

// Images
import Pin from 'img/pin-active.inline.svg'
import SwiperArrow from 'img/swiper-arrow.svg'

// Interface
import { TextProps } from 'components/flex/Text/Shell'
import Share from 'components/elements/Misc/Share'

const SliderWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  margin-top: -10vh;
`

const GradientEnd = styled.div`
  position: absolute;
  right: -70px;
  top: 0;
  z-index: 2;
  height: 100%;
  width: 223px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    #ffffff 33.1%,
    rgba(255, 255, 255, 0.87) 66.36%,
    rgba(255, 255, 255, 0) 100%
  );
`

const GradientStart = styled.div`
  position: absolute;
  left: -70px;
  top: 0;
  z-index: 2;
  height: 100%;
  width: 223px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    #ffffff 33.1%,
    rgba(255, 255, 255, 0.87) 40.36%,
    rgba(255, 255, 255, 0) 100%
  );
`

const NavigationWrapper = styled.div`
  position: absolute;
  top: calc(50% - 27.5px);
  z-index: 3;
  & .custom-swiper-button-prev {
    content: url(${SwiperArrow});
    transform: rotate(180deg);
    @media (min-width: 992px) {
      margin-left: 3rem;
    }
    @media (max-width: 991px) {
      margin-left: 0.5rem;
    }
    @media (max-width: 575px) {
      width: 40px;
      height: 40px;
    }
  }

  & .custom-swiper-button-next {
    content: url(${SwiperArrow});
    @media (min-width: 992px) {
      margin-right: 3rem;
    }
    @media (max-width: 991px) {
      margin-right: 0.5rem;
    }
    @media (max-width: 575px) {
      width: 40px;
      height: 40px;
    }
  }

  & .swiper-slide {
    position: unset;
  }

  & .swiper-button-disabled {
    opacity: 0;
  }
`

const Image = styled(Plaatjie)`
  border-radius: 24px;
  height: 285px;
  width: 300px;
  @media (max-width: 1199px) {
    height: 225px;
    width: 240px;
  }
  @media (max-width: 991px) {
    height: 185px;
    width: 200px;
  }

  @media (max-width: 575px) {
    height: 175px;
    width: 190px;
  }
`

const ContentWrapper = styled.div`
  margin-top: -6rem;
  border-radius: 24px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);

  & h1 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 50px;
    }
  }

  & ul li {
    line-height: 25px;
  }

  @media (min-width: 992px) {
    padding: 8rem 3rem 3rem 3rem;
  }
  @media (max-width: 991px) {
    padding: 8rem 1rem 1rem 1rem;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 0;
    font-weight: 500;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
`

const Location = styled.div`
  & h2 {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & svg {
    width: 33px;
    height: 33px;
  }
`

const Background = styled.div`
  background: rgb(248, 248, 248);
  background: linear-gradient(
    90deg,
    rgba(248, 248, 248, 0) 0%,
    rgba(248, 248, 248, 1) 23%,
    rgba(248, 248, 248, 1) 50%,
    rgba(248, 248, 248, 1) 73%,
    rgba(248, 248, 248, 0) 100%
  );
  height: 20vh;
`

const TextGallery: React.FC<TextProps> = ({ fields, location }) => {
  SwiperCore.use([Navigation])

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [slideEnd, setSlideEnd] = React.useState(false)

  const project = useProject()

  return (
    <section className="mb-5 pb-lg-5">
      <Background />
      <div className="container">
        <SliderWrapper className="position-relative">
          <Swiper
            onSlideChange={(slide) => setCurrentSlide(slide.realIndex)}
            onSlideChangeTransitionEnd={(slide) => setSlideEnd(slide.isEnd)}
            navigation={{
              prevEl: '.custom-swiper-button-prev',
              nextEl: '.custom-swiper-button-next',
            }}
            breakpoints={{
              0: {
                slidesPerView: 1.5,
              },
              576: {
                slidesPerView: 2.5,
              },
              768: {
                slidesPerView: 3.5,
              },
            }}
            spaceBetween={5}
          >
            <div className="d-none d-lg-block">
              {currentSlide === 1 && <GradientStart />}

              {!slideEnd && <GradientEnd />}
            </div>

            {fields.gallery?.map((image, index) => (
              <SwiperSlide key={index}>
                <Image image={image} alt="" />
              </SwiperSlide>
            ))}
            <NavigationWrapper className="d-flex justify-content-between w-100">
              <div className="custom-swiper-button-prev" role="button">
                Vorige
              </div>
              <div className="custom-swiper-button-next" role="button">
                Volgende
              </div>
            </NavigationWrapper>
          </Swiper>
        </SliderWrapper>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <ContentWrapper>
              {project.location && (
                <Location className="d-flex align-items-center mb-3">
                  <Pin />
                  <h2 className="mb-0">{project.location}</h2>
                </Location>
              )}
              {project.title && <h1 className="mb-3">{project.title}</h1>}
              {project?.categories?.length > 0 && (
                <ul className="mb-4">
                  {project.categories.map((category, index) => (
                    // @ts-ignore
                    <li key={index}>{category.name}</li>
                  ))}
                </ul>
              )}
              <Content content={fields.description} />
              {project.title && (
                <Share
                  heading="Deel dit project:"
                  location={location}
                  title={project.title || 'Project van PFC'}
                  body={fields.description || 'Project van PFC'}
                  categories={project.categories}
                  quote={project.title}
                  className="d-flex align-items-center mt-5"
                />
              )}
            </ContentWrapper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextGallery
