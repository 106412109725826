/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import Share from 'components/elements/Misc/Share'

// Hooks
import usePost from 'hooks/usePost'
import useVacancy from 'hooks/useVacancy'

// Interface
import { TextProps } from 'components/flex/Text/Shell'

// Images
import Pin from 'img/pin-active.inline.svg'
import Check from 'img/check.inline.svg'
import FormVacancy from '../Misc/FormVacancy'

const Wrapper = styled.div`
  border-radius: 24px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  margin-top: -10vh;
  background-color: ${({ theme }) => theme.color.light};
`

const TitleWrapper = styled.div`
  min-height: 333px;
  @media (min-width: 992px) {
    margin-left: -6rem;
    margin-top: -6rem;
  }
`

const Image = styled(Plaatjie)`
  border-radius: 24px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.3));
  @media (min-width: 992px) {
    width: 300px;
    height: 285px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 0;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
`

const Location = styled.div`
  & h2 {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & svg {
    width: 33px;
    height: 33px;
  }
`

const Details = styled.div`
  & svg {
    width: 20px;
    height: 20px;
  }
  & span {
    font-size: 16px;
  }
`

const Background = styled.div`
  background: #f8f8f8;
  height: 20vh;
`

const TextPost: React.FC<TextProps> = ({ fields, location }) => {
  const post = usePost()
  const vacancy = useVacancy()

  return (
    <section className="mb-5 pb-lg-5">
      <Background />
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <Wrapper className="position-relative p-3 p-sm-5">
              {post.title && (
                <TitleWrapper className="d-flex flex-column flex-lg-row align-items-center pb-5">
                  <Image image={post.image} alt="" className="mb-4 mb-lg-0" />
                  <div className="px-lg-5">
                    <h1>{post.title}</h1>
                  </div>
                </TitleWrapper>
              )}

              {vacancy.title && (
                <div className="d-flex justify-content-start justify-content-sm-center">
                  <h1>{vacancy.title}</h1>
                </div>
              )}

              {vacancy.location && (
                <Location className="d-flex align-items-center justify-content-start justify-content-sm-center mb-3">
                  <Pin />
                  <h2 className="mb-0">{vacancy.location}</h2>
                </Location>
              )}

              {vacancy.details && (
                <Details className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-center mb-5 ms-sm-5">
                  {vacancy.details.map(({ detail }, index) => (
                    <div className="d-flex align-items-center me-5" key={index}>
                      <Check />
                      <span className="ms-2">{detail}</span>
                    </div>
                  ))}
                </Details>
              )}

              <Content content={fields.description} />

              {vacancy.title && <FormVacancy vacancyTitle={vacancy.title} />}

              {post.title && (
                <Share
                  heading="Deel dit bericht:"
                  location={location}
                  title={post.title || 'Bericht van PFC'}
                  body={fields.description || 'Bericht van PFC'}
                  quote={post.title}
                  className="d-flex align-items-center mt-5"
                />
              )}
              {vacancy.title && (
                <Share
                  heading="Deel deze vacature:"
                  location={location}
                  title={vacancy.title || 'Vacature van PFC'}
                  body={fields.description || 'Vacature van PFC'}
                  quote={vacancy.title}
                  className="d-flex align-items-center mt-5"
                />
              )}
            </Wrapper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextPost
