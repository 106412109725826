import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/Shell'

const Text: React.FC<TextProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <ParseContent content={fields.description || ''} />
    </div>
  </section>
)

export default Text
