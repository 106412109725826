import React from 'react'
import styled from 'styled-components'
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share'

// Images
import Facebook from 'img/facebook.inline.svg'
import LinkedIn from 'img/linkedin.inline.svg'
import WhatsApp from 'img/whatsapp.inline.svg'
import Twitter from 'img/twitter.inline.svg'

const ShareContainer = styled.div`
  & h2 {
    color: ${({ theme }) => theme.color.primary};
    font-size: 18px !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & svg {
    width: 25px;
    height: 25px;
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }
`

interface ShareProps {
  heading: string
  location: any
  title: string
  body: string
  quote?: string
  categories?: Array<any>
  className?: string
}

const Share: React.FC<ShareProps> = ({
  heading,
  location,
  title,
  body,
  quote,
  categories,
  className = '',
}) => {
  const shareUrl = location ? location.href : ''

  const removeHTML = quote?.replace(/<\/?[^>]+(>|$)/g, '')
  const removeQuote = removeHTML?.replace('&#8220;', '"')
  const cleanQuote = removeQuote?.replace('&#8221;', '"')

  return (
    <ShareContainer className={className}>
      <h2 className="mb-0 me-4">{heading}</h2>
      <div>
        <FacebookShareButton
          url={shareUrl}
          quote={cleanQuote}
          title={title}
          className="me-3"
        >
          <div className="position-relative d-flex flex-column justify-content-center align-items-center">
            <div>
              <Facebook />
            </div>
          </div>
        </FacebookShareButton>
      </div>
      <div>
        <LinkedinShareButton
          url={shareUrl}
          title={title}
          summary={body}
          source={shareUrl}
          className="me-3"
        >
          <div className="position-relative d-flex flex-column justify-content-center align-items-center">
            <div>
              <LinkedIn />
            </div>
          </div>
        </LinkedinShareButton>
      </div>
      <div>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          via={shareUrl}
          hashtags={categories}
          className="me-3"
        >
          <div className="position-relative d-flex flex-column justify-content-center align-items-center">
            <div>
              <Twitter />
            </div>
          </div>
        </TwitterShareButton>
      </div>
      <div>
        <WhatsappShareButton url={shareUrl} title={title} className="me-3">
          <div className="position-relative d-flex flex-column justify-content-center align-items-center">
            <div>
              <WhatsApp />
            </div>
          </div>
        </WhatsappShareButton>
      </div>
    </ShareContainer>
  )
}

export default Share
