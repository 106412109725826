/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import ThanksVacancy from './Thanks'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    position: relative;
    margin-bottom: 2rem;

    &-consent {
      & > div {
        align-items: flex-start !important;
      }
    }
  }

  input.form-duuf-checkbox-consent {
    margin-right: 6px !important;
  }

  & a.form-duuf-checkbox-consent {
    color: ${({ theme }) => theme.color.secondary} !important;
    margin-left: 0 !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
  }

  & .form-duuf-textarea {
    height: 133px;
  }

  & .form-duuf-file-button {
    ${ButtonPrimaryCSS};

    & > button {
      padding: 3.7px 18px !important;
    }
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox {
    margin-top: 3px;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
    position: absolute;
    right: 10px;
    top: 25px;

    &-consent {
      margin-top: 52px;
    }
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    /* color: green; */
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 26px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
  & p {
    line-height: 20px;
  }
`

interface FormVacancyProps {
  vacancyTitle: string
}

const FormVacancy: React.FC<FormVacancyProps> = ({ vacancyTitle }) => (
  <div className="my-5 pb-lg-5">
    <div className="container py-5">
      <StyledFormDuuf
        // @ts-ignore
        renderStatus={ThanksVacancy}
        id={2}
        className="p-4 p-lg-5"
        generate={false}
        defaultFields={{ input_1: vacancyTitle }}
        privacyUrl="/privacyverklaring"
        privacyUrlPlacement="privacy voorwaarden"
      >
        <Content content="<h2>Solliciteer direct</h2>" className="mb-5" />
        <div className="row">
          <div className="col-md-6">
            <FormDuufGroup showIds={[3, 5]} />
          </div>
          <div className="col-md-6">
            <FormDuufGroup showIds={[4, 7]} />
          </div>
        </div>
        <div>
          <FormDuufGroup showIds={[6, 8]} />
        </div>
        <div>
          <FormDuufSubmit />
        </div>
      </StyledFormDuuf>
    </div>
  </div>
)

export default FormVacancy
