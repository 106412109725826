import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/Shell'

const ContentOne = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 60px;
    }
  }
`

const ContentTwo = styled(ParseContent)`
  border-left: 2px solid ${({ theme }) => theme.color.dark};
`

const TextCols: React.FC<TextProps> = ({ fields }) => (
  <section className="my-5 pb-lg-5">
    <div className="container ">
      <div className="row align-items-center">
        <div className="col-md-5 mb-4 mb-md-0">
          <ContentOne content={fields.description || ''} />
        </div>
        <div className="col-md-7">
          <ContentTwo
            content={fields.descriptiontwo || ''}
            className="ps-4 ps-md-5"
          />
        </div>
      </div>
    </div>
  </section>
)

export default TextCols
